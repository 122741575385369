import React, { useState } from "react";
import { Stepper, Step, Input } from "@material-tailwind/react";
import RightArrow from "../assets/icons/Arrow-right.svg";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomInput from "../components/CustomInput";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("The Name field is required"),
  country: Yup.string().required("The Country field is required"),
  email: Yup.string().required("The Email field is required"),
  dob: Yup.string().required("The Date Of Birth field is required"),
});

const Registration_3 = () => {
  const Navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(3);

  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const submitProfile = () => {
  };
  return (
    <Formik
      initialValues={{
        name: "",
        country: "",
        email: "",
        dob: "",
        // old_password: "",
        // new_password: "",
        // retype_new_password: "",
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={submitProfile}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
      }) => (
        <form>
          <div className="flex flex-col justify-center sm:items-center px-4 py-0 sm:py-4 sm:px-8  bg-background800 ">
            <div
              className={`flex flex-col bg-neutral-900  w-auto sm:w-[604px] sm:h-[703px]  pt-0 my-[30px] sm:my-11 sm:pt-8 sm:mt-[75px] sm:bg-gradient-to-b from-[#201D1D] via-[#201D1D] to-[#181515] rounded-lg`}
            >
              <div
                className={` hidden  sm:flex justify-center items-center pb-4 `}
              >
                <Stepper
                  className={`w-[200px] `}
                  activeStep={activeStep}
                  isLastStep={(value) => setIsLastStep(value)}
                  isFirstStep={(value) => setIsFirstStep(value)}
                >
                  <Step
                    className={`bg-transparent border-2 border-sky-500 `}
                    style={{
                      borderColor: "#E5D062",
                      backgroundColor: activeStep === 1 ? "#E5D062" : "#181515",
                      color: activeStep === 1 ? "black" : "#E5D062",
                    }}
                    onClick={() => setActiveStep(0)}
                  >
                    1
                  </Step>
                  <Step
                    className={`bg-gold border-2 border-sky-500 `}
                    style={{
                      borderColor: "#E5D062",
                      backgroundColor: activeStep === 2 ? "#E5D062" : "#181515",
                      color: activeStep === 2 ? "black" : "#E5D062",
                    }}
                    onClick={() => setActiveStep(1)}
                  >
                    2
                  </Step>
                  <Step
                    className={`bg-transparent border-2 border-sky-500 `}
                    style={{
                      borderColor: "#E5D062",
                      backgroundColor: activeStep === 3 ? "#E5D062" : "#181515",
                      color: activeStep === 3 ? "black" : "#E5D062",
                    }}
                    onClick={() => setActiveStep(2)}
                  >
                    3
                  </Step>
                </Stepper>
              </div>

              <div className={`sm:hidden pb-[31px]`}>
                <div
                  className={`flex justify-center items-center text-lg text-Light_Gray`}
                >
                  <span className={``}>Create your account</span>
                </div>
                <div
                  className={`flex justify-center items-center text-Light_Gray text-sm`}
                >
                  <span>Join a community of over 200k people!</span>
                </div>
              </div>
              <div
                className={`hidden sm:flex text-2xl font-['DIN'] text-white justify-center`}
              >
                <span>Personal info</span>
              </div>

              <div
                className={`flex flex-col justify-center gap-4 px-4 pb-[219px] sm:pb-32 w-auto `}
              >
                <CustomInput
                  width={" w-full "}
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Name"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.name && touched.name && errors.name}
                </span>
                <CustomInput
                  width={" w-full "}
                  type="text"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Country"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.country && touched.country && errors.country}
                </span>
                <CustomInput
                  width={" w-full "}
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.email && touched.email && errors.email}
                </span>
                <CustomInput
                  width={" w-full "}
                  type="text"
                  name="dob"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Date Of Birth"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.dob && touched.dob && errors.dob}
                </span>
              </div>
              <div
                className={`hidden sm:flex justify-end items-end sm:justify-start sm:items-start px-4 `}
              >
                <Button
                  type="submit"
                  text={"Complete Registration "}
                  isBorder={false}
                  width={"w-[143px] md:w-[604px]"}
                  height={"h-[56px] md:h-[56px]"}
                  iconRight={RightArrow}
                />
              </div>
              <div
                className={`sm:hidden flex justify-end items-end sm:justify-start sm:items-start px-4 `}
              >
                <Button
                  type="submit"
                  text={"Sign Up"}
                  isBorder={false}
                  onClick={() => Navigate("/registration-success")}
                  width={"w-[143px] md:w-[604px]"}
                  height={"h-[56px] md:h-[56px]"}
                  iconRight={RightArrow}
                />
              </div>
              {/* <div className={`flex flex-col  justify-start items-start gap-4 px-4`}>
        <variant for="password">First name:</variant>

          <input
            placeholder="password"
            className={`outline-none text-white bg-transparent border-b  border-b-white100 focus:border-b-gold w-full`}
            type="tetx"
            name="password"
          />
          </div>
        <div className={`flex flex-col  justify-start items-start gap-4 px-4`}>
        <variant for="password">First name:</variant>

          <input
            placeholder="password"
            className={`outline-none text-white bg-transparent border-b  border-b-white100 focus:border-b-gold w-full`}
            type="tetx"
            name="password"
          />
          </div>
        <div className={`flex flex-col  justify-start items-start gap-4 px-4`}>
        <variant for="password">First name:</variant>

          <input
            placeholder="password"
            className={`outline-none text-white bg-transparent border-b  border-b-white100 focus:border-b-gold w-full`}
            type="tetx"
            name="password"
          />
        </div> */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Registration_3;
