import React from "react";
import RegLogo from "../assets/icons/reg_logo.svg";
import GmtMob from "../assets/images/gmt-mobile.png";
import Button from "../components/Button";
import GMTFeatures from "../components/GMTFeatures";
import { Dialog } from "@material-tailwind/react";

const DownloadApp = ({ open, setOpen }) => {
  const handleOpen = () => setOpen(!open);
  return (
    <div>
      <Button onClick={handleOpen} variant="gradient">
        Open Dialog
      </Button>
      <Dialog size="xl" className="w-4/5" open={open} handler={handleOpen}>
        <div
          className={`flex flex-col justify-center items-center sm:flex-row rounded-lg sm:justify-around sm:items-start pt-[45px] bg-background800`}
        >
          <div
            className={`flex flex-col pl-6 sm:pl-0 order-last sm:order-none pb-8  `}
          >
            <div className={`flex justify-center items-center`}>
              <img src={RegLogo} />
            </div>
            <div className={`flex flex-col text-4xl text`}>
              <span className={`title text-white uppercase `}>
                This feature is only <br /> available on the
              </span>
              <span className={`text-gold `}> GMT24 app</span>
            </div>
            <div className={`flex justify-start items-start pb-[6px] pl-0  `}>
              <span className={`text-Light_Gray din-text font-normal`}>
                Download the app and enjoy all the following
                <br /> features:
              </span>
            </div>
            {/* <div className={`pb-[41px] din-text`}>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Negotiate Prices</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Special Offers</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Discover Merchants</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Sell your collection</span>
              </div>
            </div> */}
            <GMTFeatures />
            <div className={`flex flex-row gap-[19px] pb-[90px]`}>
              <div className={` sm:pb-[18px]`}>
                <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
                  <Button
                    type="submit"
                    text={"Download App"}
                    isBorder={true}
                    width={"w-[191px] md:w-[191px]"}
                    height={"h-[56px] md:h-[56px]"}
                  />
                </a>
              </div>
              <div className={` hidden sm:block `}>
                <Button
                  type="submit"
                  text={"Continue Shopping"}
                  isBorder={true}
                  onClick={handleOpen}
                  width={"w-[191px] md:w-[191px]"}
                  height={"h-[56px] md:h-[56px]"}
                />
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col justify-center items-center bg-blue-gray-500 `}
          >
            <div
              className={`flex justify-center 
        `}
            >
              <img src={GmtMob} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DownloadApp;
