import { Button, Card, CardBody, CardFooter, CardHeader, Typography } from '@material-tailwind/react'
import React from 'react'

function HomePageSkeleton() {
  return (
    <div className="grid grid-cols-2 gap-[36px] sm:grid md:grid-cols-3 lg:grid-cols-5">
    {[...Array(5)].map((_, index) => (
      <Card
        key={index}
        className="w-[188px] min-h-[280px] mb-0 sm:mb-9 sm:w-[300px] sm:max-h-[465px] rounded-xl sm:rounded-[10px] animate-pulse bg-gray-900"
      >
        <CardHeader 
          shadow={false}
          floated={false}
          className="w-[150px] h-[130px] sm:w-[270px] sm:h-[286px] object-cover rounded-none sm:rounded-t-[10px] relative grid place-items-center bg-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-12 w-12 text-gray-900"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
          </svg>
        </CardHeader>
        <CardBody>
          <Typography
            as="div"
            variant="h1"
            className="mb-4 h-3 w-38 sm:h-3 sm:w-56 rounded-full bg-gray-500"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="paragraph"
            className="mb-2 h-2 w-full rounded-full bg-gray-500"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="paragraph"
            className="mb-2 h-2 w-full rounded-full bg-gray-500"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="paragraph"
            className="mb-2 h-2 w-full rounded-full bg-gray-500"
          >
            &nbsp;
          </Typography>
        </CardBody>
        <CardFooter className="pt-0">
          <Button
            disabled
            tabIndex={-1}
            className="h-6 w-20 bg-gray-500 shadow-none hover:shadow-none"
          >
            &nbsp;
          </Button>
        </CardFooter>
      </Card>
    ))}
  </div>
  
  )
}

export default HomePageSkeleton
