import React, { useState } from "react";
import Header from "../components/Header";
import Button from "../components/Button";
import RightArrow from "../assets/icons/Arrow-right.svg";
import * as Yup from "yup";
import { Formik } from "formik";
import { Stepper, Step } from "@material-tailwind/react";
import HorizontalTabl from "../components/HorizontalTabl";
import CustomInput from "../components/CustomInput";
const validationContact = Yup.object().shape({
  mobile_no: Yup.number()
    .max(10, "Mobile Number should not exceed 10.")
    .required("Please enter Mobile Number"),
});
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("The First Name field is required"),
  // lastName: Yup.string().required("The Last Name field is required"),
  email: Yup.string()
    .email("The email must be a valid email address.")
    .required("The Email field is required"),
  // password: Yup.string()
  //   .required("The Password field is required")
  //   .matches(
  //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*)[A-Za-z\d]{8,}$/,
  //     `Must Contain 8 Characters, One Uppercase, One Lowercase,
  //       One Number and one special case Character [@$!%*#?&-_]`
  //   ),
  // mobile_no: Yup.number()
  //   .max(10, "Mobile Number should not exceed 10.")
  //   .required("Please enter Mobile Number"),
  birth_date: Yup.string().required("Please Enter Birth Date."),
  country: Yup.string().required("Please Select Country."),
  // country_code: Yup.string().required("Please Select Country Code."),
});

const genders = [
  {
    key: "male",
    value: "Male",
  },
  {
    key: "female",
    value: "Female",
  },
];

function SignUp() {
  const onSubmit = async (values, { setSubmitting }) => {
  
  };

  const [activeStep, setActiveStep] = useState(0);

  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);

  const handleInputChange = (index, value) => {
    if (value.length === 1) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;

      setOtpValues(newOtpValues);

      // Move to the next input field if the current input has a value
      if (value !== "" && index < otpValues.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleBackspace = (index) => {
    // Move to the previous input field if the current input is empty
    if (index > 0 && otpValues[index] === "") {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }

    const newOtpValues = [...otpValues];
    newOtpValues[index] = "";

    setOtpValues(newOtpValues);
  };

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div className={` bg-background800 no-scrollbar w-100 h-100`}>
      <div
        className={`sm:w-full sm:flex sm:justify-center sm:items-center bg-background800`}
      >
        {/*  First Step */}
        <div
          className={`flex flex-col justify-start items-center px-4 sm:w-[668px] sm:py-8 sm:h-fit bg-transparent sm:bg-gradient-to-b from-[#201D1D] via-[#201D1D] to-[#181515] sm:rounded-lg`}
        >
          <div className={`flex justify-center items-center pb-4 `}>
            <Stepper
              className={`w-[200px] `}
              activeStep={activeStep}
              lineClassName={`bg-gold`}
              activeLineClassName={`bg-gold`}
              isLastStep={(value) => setIsLastStep(value)}
              isFirstStep={(value) => setIsFirstStep(value)}
            >
              <Step
                className={`bg-background800 border-2 border-gold text-gold`}
                activeClassName={`bg-gold text-background900 `}
                onClick={() => setActiveStep(0)}
                completedClassName={`bg-background800`}
              >
                1
              </Step>
              <Step
                className={`bg-background800 border-2 border-gold text-gold`}
                activeClassName={`bg-gold text-background900 `}
                completedClassName={`bg-background800`}
                onClick={() => setActiveStep(1)}
              >
                2
              </Step>
              <Step
                activeClassName={`bg-gold text-background900 `}
                className={`bg-background800 border-2 border-gold text-gold`}
                onClick={() => setActiveStep(2)}
                completedClassName={`bg-background800`}
              >
                3
              </Step>
            </Stepper>
          </div>
          {activeStep === 0 && (
            <div
              className={`flex flex-col justify-center items-center gap-4 sm:w-[315px]`}
            >
              <span className={`title text-lg text-white text-center`}>
                Enter Your Mobile Number
              </span>
              <span
                className={`text text-xs text-whiteSecondary opacity-50 text-center`}
              >
                We've sent a 4 digit code to your phone number.
                <br /> Please enter the verification code.
              </span>
              <Formik
                initialValues={{ mobile_no: "" }}
                validationSchema={validationContact}
                onSubmit={(values) => {
                 
                  
                }}
              >
               {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
              }) => (
                  <form onSubmit={handleSubmit}>
                    <div
                      className={`w-full self-start sm:self-center flex flex-row justify-center items-center py-4  gap-1`}
                    >
                      <div className={`h-4 w-4 bg-red-500 rounded-full`}></div>
                      <span className={`text text-base text-whiteSecondary`}>
                        +951
                      </span>
                      <CustomInput
                        // <input name="mobile_no"
                        className={`bg-transparent text text-white focus:outline-none text text-base border-b-white100 focus:border-b-gold`}
                        name="mobile_no"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile_no}
                        type="text"
                        width={`w-full`}
                        placeholder="Enter Phone Number"
                      />
                    </div>
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.mobile_no &&
                        touched.mobile_no &&
                        errors.mobile_no}
                    </span>
                  </form>
                )}
              </Formik>
              <div
                className={`flex w-full justify-end items-end sm:justify-center sm:items-center `}
              >
                <button
                  className="flex flex-row justify-center items-center px-4 py-2 rounded-full cursor-pointer bg-background900 text-tiny text-gold w-[165px] md:w-[165px] h-[56px] md:h-[56px]"
                  type="submit"
                  // isBorder={false}
                  // onClick={() => console.log("Download App")}
                  width={"w-full"}
                  height={"h-[56px] md:h-[56px]"}
                >
                  Request Code
                </button>
                {/* <Button
                    type="submit"
                    text={"Request Code"}
                    isBorder={false}
                    onClick={() => console.log("Download App")}
                    width={"w-[165px] md:w-[165px]"}
                    height={"h-[56px] md:h-[56px]"}
                    iconRight={RightArrow}
                  /> */}
              </div>
            </div>
          )}
          {/* Second Step */}
          {activeStep === 1 && (
            <div
              className={`flex flex-col justify-center items-center gap-[14px] sm:gap-4 sm:w-[315px]`}
            >
              <div className={`flex flex-col justify-center items-center`}>
                <span className={`title text-lg text-white text-center`}>
                  Enter The Four Digit Code <br />
                  Send To:
                </span>
                <span className={`text text-base text-gold`}>
                  {" "}
                  +951 492384923
                </span>
                <span
                  className={`text text-xs text-whiteSecondary opacity-50 text-center`}
                >
                  We've sent a 4 digit code to your phone number. <br />
                  Please enter the verification code.
                </span>
              </div>

              {/* <div className={`w-full self-start sm:self-center border-b-[1px] border-opacity-10 border-whiteSecondary flex flex-row justify-center items-center py-4  gap-1`}>
                                <div className={`flex flex-row justify-center items-center h-4 w-4 border-b-[1px] border-gold pb-4`}>
                                    <input name="OTP_1" className={`bg-transparent focus:outline-none title text-2xl text-white bg-green-500 w-4`}  />
                                </div>
                                <div className={`flex flex-row justify-center items-center h-4 w-4 border-b-[1px] border-gold pb-4`}>
                                    <input name="OTP_2" className={`bg-transparent focus:outline-none title text-2xl text-white w-4`}  />
                                </div>
                                <div className={`flex flex-row justify-center items-center h-4 w-4 border-b-[1px] border-gold pb-4`}>
                                    <input name="OTP_3" className={`bg-transparent focus:outline-none title text-2xl text-white w-4`}  />
                                </div>
                                <div className={`flex flex-row justify-center items-center h-4 w-4 border-b-[1px] border-gold pb-4`}>
                                    <input name="OTP_4" className={`bg-transparent focus:outline-none title text-2xl text-white w-4`}  />
                                </div>
                            </div> */}
              <div className={`flex gap-2`}>
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    maxLength="1"
                    className={`outline-none text-center text-white text-3xl border-opacity-10 bg-transparent border-b-2 pb-4 border-b-white100 focus:border-b-gold w-14 sm:[295px]`}
                    type="number"
                    name="password"
                    value={value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") {
                        handleBackspace(index);
                      }
                    }}
                  />
                ))}
              </div>
              <div className="flex  din-text text-Light_Gray text-xs mb-[22px] sm:mb-0 mt-[337px] sm:mt-1 text-center">
                <span>
                  Didn’t receive the SMS?
                  <br /> Request new code in 04:15
                </span>
              </div>
              <div
                className={`flex flex-col w-full justify-center items-center sm:justify-center sm:items-center  `}
              >
                <Button
                  type="submit"
                  text={"Next"}
                  isBorder={false}
                  width={"w-[165px] md:w-[165px]"}
                  height={"h-[56px] md:h-[56px]"}
                  iconRight={RightArrow}
                />
              </div>
            </div>
          )}

          {/* Third Step */}
          {activeStep === 2 && (
            <Formik
              initialValues={{
                first_name: "",
                country: "",
                email: "",
                birth_date: "",
              }}
              validationSchema={validationSchema} 
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    className={`flex flex-col justify-center items-center gap-4`}
                  >
                    <span className={`text text-lg text-white text-center`}>
                      Personal Info
                    </span>
                    <HorizontalTabl tabs={genders} />
                    <div>
                      <CustomInput
                        width={" w-[343px] sm:w-[604px] "}
                        name="first_name"
                        placeholder="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                      />
                      <span className={`text-gold text-xs mt-1`}>
                        {errors.first_name &&
                          touched.first_name &&
                          errors.first_name}
                      </span>
                    </div>
                    <div>
                      <CustomInput
                        width={" w-[343px] sm:w-[604px] "}
                        name="country"
                        placeholder="Country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                      />
                      <span className={`text-gold text-xs mt-1`}>
                        {errors.country && touched.country && errors.country}
                      </span>
                    </div>
                    <div>
                      <CustomInput
                        width={" w-[343px] sm:w-[604px] "}
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <span className={`text-gold text-xs mt-1`}>
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>
                    <div>
                      <CustomInput
                        width={" w-[343px] sm:w-[604px] "}
                        name="birth_date"
                        placeholder="Date of Birth"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.birth_date}
                      />
                      <span className={`text-gold text-xs mt-1`}>
                        {errors.birth_date &&
                          touched.birth_date &&
                          errors.birth_date}
                      </span>
                    </div>
                    <div
                      className={`flex w-full justify-end items-end sm:justify-center sm:items-center pt-32`}
                    >
                      <button
                        className="flex flex-row justify-center items-center px-4 py-2 rounded-full cursor-pointer bg-background900 text-tiny text-gold w-full h-[56px] md:h-[56px]"
                        type="submit"
                        text={"Complete Registration"}
                        width={"w-full"}
                        height={"h-[56px] md:h-[56px]"}
                      >
                        Complete Registration
                      </button>
                      {/* <Button
                        type="submit"
                        text={"Complete Registration"}
                        isBorder={false}
                        onClick={() => console.log("Download App")}
                        width={"w-full"}
                        height={"h-[56px] md:h-[56px]"}
                           /> */}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
