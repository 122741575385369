import React from 'react'

function WatchFeature({icon, icon_name, feature, feature_value}) {
  return (
    <div className='flex flex-row gap-2 '>
        <div className='flex flex-row w-8 h-8 justify-center items-center p-1 bg-background900 rounded-[5px]'>
            <img src={icon} alt={icon_name} className={`w-5 h-5`} />
        </div>
        <div className='flex flex-col '>
            <span className='text text-white text-[12px]'>{feature}</span>
            <span className='text text-whiteSecondary opacity-40 min-w-max text-[12px]'>{feature_value} { feature === "Size" && "mm"}</span>
        </div>
    </div>
  )
}

export default WatchFeature