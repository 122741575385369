import React from "react";
import HeroButton from "./HeroButton";
import HeroAction from "./HeroAction";
import { useNavigate } from "react-router-dom";
import Slideshow from "./Carousel";
import carousel_img from "../../src/assets/images/carousel-img.png";


const Hero = () => {
  const navigation = useNavigate();

  return (
    <div
      className={`bg-background800 text-tiny text-white flex flex-col justify-center items-center md:items-center md:flex-row md:justify-between md:px-16 md:pt-12 md:pb-5 gap-4 sm:gap-0`}
    >
      <div className="order-1">
        <HeroAction />
      </div>
      <div className="order-first sm:order-2 mt-4 sm:mt-0 hidden sm:block">
        <Slideshow />
      </div>
      <div className="order-first sm:order-2 mt-4 sm:mt-0 block sm:hidden">
          <div
            className="inline-block w-full h-[364px] "
          >
            <img
              src={carousel_img}
              alt={`watch`}
              className="w-full h-full object-cover"
            />
          </div>
      </div>
      <div
        className={`order-3 flex flex-col justify-center items-center  gap-4`}
      >
        <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
        <HeroButton
          startingText="New in"
          heading="GMT24 APP"
          actionText="Download Now"
        />
        </a>
        <HeroButton
          startingText="New in"
          heading="Watch"
          actionText="Shop Now"
          onClick={() => navigation("/watch")}
        />
        <HeroButton
          startingText="New in"
          heading="Accessory"
          actionText="Shop Now"
          onClick={() => navigation("/accessory")}
        />
      </div>
    </div>
  );
};

export default Hero;
